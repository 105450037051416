import _ from 'lodash'
import './modules/utm'
import './modules/api'
import './modules/scroll'
import './modules/popup'
import './modules/popup-utils'
import './modules/popup-dark'
import './modules/popup-forecasts-user'
import './modules/popup-promotion-end'
import './modules/counter'
import './modules/animation'

document.addEventListener('touchstart', function () {}, true)

const popup = document.querySelector('.popup-dark--form')
if (popup) {
  popup.remove()
}
